// src/components/ManifiestoPage.tsx
import React from 'react';
import PageTemplate from './PageTemplate';

const ManifiestoPage: React.FC = () => (
  <PageTemplate title="JARAFEST" isContentPage={true}>
    <div className="manifiesto-content space-y-8 font-railway">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <div className="text-gray-600 mb-8 max-w-2xl mx-auto">
          <p className="mb-4">
            Jarafest es un <strong>festival DIY</strong> que se celebrará el próximo <strong>31 de agosto de 2024</strong> en <strong>Retamoso de la Jara</strong> gracias a la colaboración desinteresada de una docena de bandas y artistas procedentes de varias ciudades españolas. Desde Gijón a Talavera de la Reina, pasando por Toledo, Madrid, Valencia o Barcelona.
          </p>
          <p className="mb-4">
            Completamente <strong>autogestionado y organizado por los propios vecinos</strong> de Retamoso de la Jara, la primera edición del <strong>#Jarafest 2024</strong> se enmarca dentro de la campaña <strong>"Sí a la Jara, no al purín"</strong>. Una campaña de financiación colectiva impulsada desde la asociación vecinal <strong>Retamoso Sostenible</strong> para denunciar la instalación de una <strong>macrogranja de 7200 cerdos</strong> en Retamoso de la Jara y alertar de la situación que sufre la provincia de Toledo a causa de los efectos de la ganadería industrial.
          </p>
          <p className="mb-4">
            Sí, hay <strong>otra forma de vivir el mundo rural y defender el territorio</strong>, y es posible hacerlo también desde la música, la cultura y el fomento de la participación vecinal. Con esta 1ª edición del Jarafest queremos aportar nuestro granito y reivindicar, a golpe de música en directo y buena compañía, <strong>un futuro digno para nuestra comarca</strong>.
          </p>
          <p className="mb-4">
            Por ello, y gracias a la colaboración desinteresada de mucha gente bonita, <strong>todos los beneficios del Jarafest 2024 se destinarán a apoyar la campaña "Sí a la Jara no al Purín"</strong> y seguir impulsando movimientos de resistencia vecinal contra los estragos de la ganadería industrial.
          </p>
          <p className="text-xl font-bold text-center mb-2">
            <strong>¡Hazlo posible!</strong>
          </p>
          <p className="text-xl font-bold text-center">
            <strong>#SíALaJaraNoAlPurín</strong>
          </p>
        </div>
      </div>
    </div>
  </PageTemplate>
);

export default ManifiestoPage;