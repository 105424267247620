import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './JaraFestPoster.css';
import './ContentPages.css';
import HamburgerMenu from './HamburgerMenu';

interface PageTemplateProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  isContentPage?: boolean;
}

const PageTemplate: React.FC<PageTemplateProps> = ({ title, children, className, isContentPage = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [titleVisibility, setTitleVisibility] = useState('visible');
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);

  const handleMenuToggle = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
    if (isContentPage) {
      setTitleVisibility(isOpen ? 'hidden' : 'visible');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const newOpacity = Math.max(1 - scrollPosition / 100, 0);
      setOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isContentPage) {
      setIsMenuOpen(false);
      setTitleVisibility('visible');
    }
  }, [location, isContentPage]);

  return (
    <div className={`page-container ${isContentPage ? 'content-page-container' : 'poster-container'} ${className}`}>
      {isContentPage && <div className="body-color-layer"></div>} {/* Ensure this line is included */}
      {isContentPage && <div className="content-page-background"></div>} {/* Ensure this line is included */}
      <div className="fixed-elements" style={{ opacity }}>
        <HamburgerMenu onMenuToggle={handleMenuToggle} />
        <Link to="/" className="home-button" title="Volver a la página principal">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        </Link>
        {isContentPage && (
          <h1 className={`content-title ${titleVisibility}`}>
            {title}
          </h1>
        )}
      </div>
      <div className={isContentPage ? 'content-page' : 'poster'}>
        {!isContentPage && (
          <h1 className="title">
            {title}
          </h1>
        )}
        <div ref={contentRef} className={isContentPage ? 'content-body' : 'page-content'}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;
