// src/components/ApoyaPage.tsx
import React from 'react';
import PageTemplate from './PageTemplate';

const linkStyle: React.CSSProperties = {
  color: 'rgb(202, 138, 4)',
  textDecoration: 'none',
  fontWeight: 'bold',
  transition: 'color 0.2s',
};

const ApoyaPage: React.FC = () => (
  <PageTemplate title="APOYA" isContentPage={true}>
    <div className="apoya-content space-y-8 font-railway">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <div className="text-gray-600 mb-8 max-w-2xl mx-auto">
          <p className="mb-4">
            <strong>Jarafest es un festival DIY completamente autogestionado sin trabajadores remunerados ni cachés. </strong>
            Surge de manera solidaria y gracias a la colaboración desinteresada de decenas de personas y artistas
            con el fin de apoyar la campaña "<strong>Sí a la Jara, no al Purín</strong>".
          </p>
          <p className="mb-4">
            Una campaña impulsada por la <strong>Asociación Vecinal Retamoso Sostenible</strong> para frenar la instalación de una
            macrogranja de 7200 cerdos en Retamoso de la Jara y denunciar la situación que sufre la provincia de
            Toledo a causa de la ganadería industrial.
          </p>
          <p className="mb-4">
            Todo lo recaudado en el festival irá destinado a conseguir los <strong>10.000 euros necesarios</strong> para denunciar
            el caso ante los tribunales y seguir impulsando movimientos de resistencia vecinal contra la invasión
            descontrolada de proyectos de ganadería industrial.
          </p>
          <p className="mb-4">
            Puedes hacerlo posible participando en el crowdfunding en{' '}
            <a 
              href="https://www.goteo.org/project/campana-si-a-la-jara-no-al-purin" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={linkStyle}
            >
              <strong>Sí a la Jara, no al Purín</strong>
            </a>
          </p>
          <div className="flex justify-center my-8">
            <iframe 
              src="//www.goteo.org/widget/project/campana-si-a-la-jara-no-al-purin" 
              width="300" 
              height="492" 
              frameBorder="0" 
              scrolling="no"
              title="Goteo crowdfunding widget"
            ></iframe>
          </div>
          <p className="mb-4"><strong>¡Muchas gracias!</strong></p>
          <p className="mb-2"><strong>#RetamosoSinMacrogranjas</strong></p>
          <p><strong>#NiEnTuPuebloNiEnElMío</strong></p>
        </div>
      </div>
    </div>
  </PageTemplate>
);

export default ApoyaPage;