import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PageTemplate from './PageTemplate';
import { MapPin } from 'lucide-react';

// Definir el icono por defecto
const DefaultIcon = L.icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/libs/leaflet/1.7.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const ComoLlegarPage: React.FC = () => {
  const position: [number, number] = [39.7459583, -4.7575644];

  return (
    <PageTemplate title="CÓMO LLEGAR" isContentPage={true}>
      <div className="como-llegar-content space-y-8 font-railway">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="como-llegar text-gray-600 mb-8 max-w-2xl mx-auto">
            <h3 className="text-xl font-bold ">En coche</h3>
            <p>
              <strong>Desde Talavera de la Reina:</strong>
            </p>
            <div className="map-link-container">
              <a href="https://maps.app.goo.gl/fizSNqBxikgcVGPr5" className="map-link">
                Ver en el mapa
              </a>
            </div>
            <p>
              <strong>Desde Toledo:</strong>
            </p>
            <div className="map-link-container">
              <a href="https://maps.app.goo.gl/MsufCfzUw6Ro8wUh9" className="map-link">
                Ver en el mapa
              </a>
            </div>
            <p>
              <strong>Desde Madrid:</strong>
            </p>
            <div className="map-link-container">
              <a href="https://maps.app.goo.gl/TNH7JsowqWbjDkSH6" className="map-link">
                Ver en el mapa
              </a>
            </div>
            <p>
              <strong>No hay transporte público los sábados</strong> 🙁
            </p>
            <h3 className="text-xl font-bold mt-4">Transporte Público</h3>
            <p>
              Desde Talavera de la Reina sale un único autobús al día a las 14.30h pero no hay servicio los sábados. Para
              volver el domingo 1 de septiembre, a las 16.45h pasa un autobús por Retamoso de la Jara a Talavera de la
              Reina.
            </p>
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="mb-4">
            <h3 className="text-xl font-bold mb-2">Ubicación</h3>
            <MapContainer center={position} zoom={13} style={{ height: '400px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={position}>
                <Popup>
                  A.C.R La Higuera
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="flex items-start space-x-2">
            <p className="text-gray-600 text-lg">
              Queremos <strong>pueblos vivos y con servicios de calidad accesibles a todos los vecinos</strong>, y la mejor
              manera de reivindicarlos es llenando sus calles de vida. El Jarafest también es una oportunidad de
              reivindicar otras maneras de impulsar la vida rural, y con él esperamos aportar nuestro granito por un futuro
              más digno para los habitantes de nuestra comarca.
            </p>
          </div>
          <p className="text-gray-600 text-lg mt-4 font-semibold text-xl">
            <strong>¡Hazlo posible!</strong>
          </p>
        </div>
      </div>
    </PageTemplate>
  );
};

export default ComoLlegarPage;