import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HamburgerMenu.css';

interface HamburgerMenuProps {
  onMenuToggle?: (isOpen: boolean) => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ onMenuToggle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
    if (onMenuToggle) {
      onMenuToggle(!isOpen);
    }
  };

  useEffect(() => {
    const rightTextElements = document.querySelectorAll('.right-text');
    rightTextElements.forEach((element) => {
      if (isOpen) {
        element.classList.add('hidden');
      } else {
        element.classList.remove('hidden');
      }
    });

    const titleElement = document.querySelector('.title');
    if (titleElement) {
      if (isOpen) {
        titleElement.classList.add('moved-title');
      } else {
        titleElement.classList.remove('moved-title');
      }
    }
  }, [isOpen]);

  return (
    <div className="hamburger-menu">
      <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={handleMenuClick}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div className={`menu-content ${isOpen ? 'show' : ''}`}>
        <Link to="/manifiesto" onClick={handleMenuClick}>JARAFEST</Link>
        <Link to="/programa" onClick={handleMenuClick}>PROGRAMA</Link>
        <Link to="/entradas" onClick={handleMenuClick}>ENTRADAS</Link>
        <Link to="/como-llegar" onClick={handleMenuClick}>CÓMO LLEGAR</Link>
        <Link to="/apoya" onClick={handleMenuClick}>APOYA</Link>
      </div>
    </div>
  );
};

export default HamburgerMenu;