// src/components/EntradasPage.tsx
import React, { useEffect } from 'react';
import PageTemplate from './PageTemplate';

const EntradasPage: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.defer = true;
    script.setAttribute('data-use-service-core', '');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageTemplate title="ENTRADAS" isContentPage={true}>
      <div className="entradas-content space-y-12 font-railway max-w-2xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="text-gray-600">
            <p className="mb-4 text-xl font-bold">
            </p>
            <strong>Jarafest</strong> es un festival solidario organizado gracias al trabajo voluntario de decenas de personas y artistas, y todo lo recaudado irá destinado a apoyar la campaña <a href="https://www.goteo.org/project/campana-si-a-la-jara-no-al-purin" style={{ color: '#ca8a04' }}><strong>Sí a la Jara, no al Purín</strong></a>. Para cubrir los gastos del festival sugerimos una aportación de <strong>10 euros</strong> por persona en concepto de bono de apoyo. Puedes realizar tu aportación mediante <strong>transferencia, bizum o en metálico</strong> en la puerta de acceso al festival.
            <p className="text-xl font-bold">
            </p>
          </div>
        </div>
        <div className="mt-12">
          <div className="elfsight-app-9301620b-1d1a-4f25-aede-29e5c51ada0b" data-elfsight-app-lazy></div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default EntradasPage;
