// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import JaraFestPoster from './components/JaraFestPoster';
import ManifiestoPage from './components/ManifiestoPage';
import ProgramaPage from './components/ProgramaPage';
import EntradasPage from './components/EntradasPage';
import ComoLlegarPage from './components/ComoLlegarPage';
import ApoyaPage from './components/ApoyaPage';
import LegalPage from './components/LegalPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<JaraFestPoster />} />
        <Route path="/manifiesto" element={<ManifiestoPage />} />
        <Route path="/programa" element={<ProgramaPage />} />
        <Route path="/entradas" element={<EntradasPage />} />
        <Route path="/como-llegar" element={<ComoLlegarPage />} />
        <Route path="/apoya" element={<ApoyaPage />} />
        <Route path="/legal" element={<LegalPage />} />
      </Routes>
    </Router>
  );
}

export default App;