// src/components/ManifiestoPage.tsx
import React from 'react';
import PageTemplate from './PageTemplate';

const ManifiestoPage: React.FC = () => (
  <PageTemplate title="JARAFEST" isContentPage={true}>
    <div className="manifiesto-content space-y-8 font-railway">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <div className="text-gray-600 mb-8 max-w-2xl mx-auto">
          <p className="mb-4">
          27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de esos datos y la Ley Orgánica 3/2018, de 5 de diciembre, de protección de datos personales y garantía de los derechos digitales (LOPDGDD 3/2018) y siguiendo las Recomendaciones e Instrucciones emitidas por la Agencia Española de Protección de Datos (A.E.P.D), SE INFORMA: En Retamoso sostenible tratamos la información que nos facilita con el fin de prestarles el servicio solicitado y realizar la facturación del mismo. Los datos proporcionados se conservarán mientras se mantenga la relación comercial o durante los años necesarios para cumplir con las obligaciones legales. Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal. Usted tiene derecho a obtener confirmación sobre si en Retamoso Sostenible estamos tratando sus datos personales de forma correcta, puede rectificar los datos inexactos o solicitar su supresión cuando los datos ya no sean necesarios.
          </p>
        </div>
      </div>
    </div>
  </PageTemplate>
);

export default ManifiestoPage;