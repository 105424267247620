import React from 'react';
import { Link } from 'react-router-dom';
import './JaraFestPoster.css';
import HamburgerMenu from './HamburgerMenu';

const JaraFestPoster: React.FC = () => {
  return (
    <div className="poster-container">
      <HamburgerMenu />
      <div className="poster">
        <div className="central-image"></div>
        <div className="date right-text">
          13h<br />31—08
        </div>
        <img src="/images/title.png" alt="JARAFEST" className="title right-text" />
        <div className="animated-text-block">
          <div className="location">RETAMOSO DE LA JARA _ TOLEDO</div>
          <div className="lineup">
            IMPOSTORA • DJ MARE • SCR<br />
            QUICKLIME • CHIQUITA Y CHATARRA <br />
            SR. GÓMEZ • CLARA TE CANTA<br />
            PHANTOM CREST • PERFIL BAJO<br />
            POWER BALANCE • Y MÁS!
          </div>
          <div className="hashtag">
            #SIALAJARA<br />NOALPURIN
          </div>
        </div>
        <div className="all-links">
          <Link to="/manifiesto">JARAFEST</Link>
          <Link to="/programa">PROGRAMA</Link>
          <Link to="/entradas">ENTRADAS</Link>
          <Link to="/como-llegar">CÓMO LLEGAR</Link>
          <Link to="/apoya">APOYA</Link>
        </div>
      </div>
    </div>
  );
};

export default JaraFestPoster;