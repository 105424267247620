import React from 'react';
import { Calendar, Clock } from 'lucide-react';
import PageTemplate from './PageTemplate';
import './lineup-styles.css';

interface Artist {
  name: string;
  link: string | null;
}

const ArtistLink: React.FC<Artist> = ({ name, link }) => {
  const content = (
    <span className={`lineup-artist ${name === 'Y MÁS!' ? 'lineup-artist-special' : ''}`}>
      {name.toUpperCase()}
    </span>
  );

  return link ? (
    <a href={link} target="_blank" rel="noopener noreferrer" className="lineup-artist-link">
      {content}
    </a>
  ) : (
    <span>{content}</span>
  );
};

const ProgramaPage: React.FC = () => {
  const artists: Artist[] = [
    { name: 'Impostora', link: null },
    { name: 'Power Balance', link: 'https://powerbalance.bandcamp.com/album/power-balance' },
    { name: 'Dj Mare', link: null },
    { name: 'Chiquita y Chatarra', link: 'https://discoshumeantes.bandcamp.com/album/chiquita-y-chatarra-niagara-fallers-lp' },
    { name: 'SCR', link: 'https://scr.eslaeko.net/' },
    { name: 'Clara te canta', link: 'https://claratecanta.bandcamp.com' },  
    { name: 'Quicklime', link: null },
    { name: 'Sr. Gómez', link: 'https://monasteriodecultura.com/' },
    { name: 'Phantom Crest', link: 'https://phantomcrest.bandcamp.com/' },
    { name: 'Perfil Bajo', link: 'https://www.youtube.com/watch?v=pAJFKDfsCdI&t=10s' },
    { name: 'Y MÁS!', link: null },
  ];

  // Dividir los artistas en líneas de 2
  const artistLines = [];
  for (let i = 0; i < artists.length; i += 2) {
    artistLines.push(artists.slice(i, i + 2));
  }

  return (
    <PageTemplate title="PROGRAMA" isContentPage={true}>
      <div className="programa-content space-y-8 font-railway">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="flex justify-center items-center space-x-4 mb-6">
            <Calendar className="w-6 h-6 text-gray-500" />
            <span className="text-xl font-bold"> 31 de agosto  </span>
            <Clock className="w-6 h-6 text-gray-500 ml-4" />
            <span className="text-xl font-bold"> 13:00 - 00:00</span>
          </div>
          
          <div className="mt-16"></div>
          
          <div className="lineup-container text-xl mb-8">
            {artistLines.map((line, lineIndex) => (
              <div key={lineIndex} className="lineup-line">
                <span className="lineup-bullet">•</span>
                {line.map((artist, artistIndex) => (
                  <React.Fragment key={artist.name}>
                    <ArtistLink {...artist} />
                    <span className="lineup-bullet">•</span>
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
          
          <div className="text-gray-600 text-center max-w-2xl mx-auto">
            <p className="mb-4">
              <strong>Jarafest</strong> abrirá sus puertas con la participación desinteresada de <strong>una docena de bandas y artistas</strong>.
              Sin ellas este festival no sería posible. Desde la organización del festival queremos mostrar
              nuestro más profundo <strong>agradecimiento por la ola de solidaridad y apoyo</strong> que hemos recibido.
            </p>
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="flex items-start space-x-2">
            <p className="text-gray-600 text-lg">
              Habrá <strong>bebida y comida a precios populares</strong> y <strong>actividades para todos los públicos</strong> durante toda la jornada.
            </p>
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6">
          <p className="text-gray-600 text-lg font-semibold text-center">
            <strong>¡Os esperamos!</strong>
          </p>
        </div>
      </div>
    </PageTemplate>
  );
};

export default ProgramaPage;